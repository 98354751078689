@use '@on3/styles/variables' as var;

$star-size: 16px;
$star-size-lg: 20px;
$star-size-small: 14px;

.starRating {
  display: inline-flex;
  flex-direction: column;
}

.starWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkWrapper {
  line-height: 1;
}

.star {
  font-size: 16px;
}

.small {
  font-size: 14px;
}

.large {
  font-size: 20px;
}

.consesusFiveStar {
  width: auto;
  margin-left: 4px;
  padding: 0 3px;
  color: var.$white;
  font-size: 15px;
  background-color: var.$on3primary;
  border-radius: 10px;
}

.overallRating {
  color: var.$neutralDark;
  font-size: 14px;
  font-family: var.$geist;
  letter-spacing: 0.15px;
  border-radius: 2px;
  padding: 1px 2px;

  &.border {
    border: 1px solid var.$neutral;
  }

  &.bolded {
    font-weight: 700;
  }

  &.gray {
    color: var.$gray;
  }

  &.small {
    font-size: 12px;
  }

  @media screen and (max-width: 375px) {
    padding: 1px 4px;
    margin-left: 0;
  }
}

.teamLogo {
  height: 17px;
  width: 17px;
}

.ranksWrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 4px 0 0;
}

.ranksLabel {
  margin: 0;
}

.ranksNumberWrapper {
  display: flex;
}

.ranksLabel,
.ranksNumber {
  color: var.$gray;
  font-weight: 600;
  font-size: 11px;
  font-family: var.$archivo-narrow;
  letter-spacing: 0.15px;
  line-height: 1;
  align-self: center;
}

.ranksNumber {
  margin: 0 4px;
  color: var.$on3primary;
}
